import { createApp } from 'vue';
import { Amplify } from 'aws-amplify';
import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-vue';
I18n.putVocabularies(translations);
I18n.setLanguage('es');

import App from './App.vue';
import awsconfig from './aws-exports';

import "@/assets/styles.css";

createApp(App).mount('#app');

const updatedAwsConfig = {
    ...awsconfig,
    oauth: {
        ...awsconfig.oauth,
        domain: process.env.VUE_APP_OAUTH_DOMAIN || awsconfig.oauth.domain,
        redirectSignIn: process.env.VUE_APP_REDIRECT_SIGN_IN || awsconfig.oauth.redirectSignIn,
        redirectSignOut: process.env.VUE_APP_REDIRECT_SIGN_OUT || awsconfig.oauth.redirectSignOut,
    }
}

Amplify.configure(updatedAwsConfig);

I18n.putVocabularies({
    es: {
        'or': 'o incia sesión con',
        'Enter your Email': 'Ingrese su correo electrónico',
        'Enter your Password': 'Ingrese su contraseña',
        'Name': 'Nombre completo',
        'Enter your Name': 'Ingrese su nombre completo',
        'User does not exist.': 'El usuario no existe.',
        'Please confirm your Password': 'Confirme su contraseña',
        'Invalid verification code provided, please try again.': 'Código de verificación inválido, por favor intente nuevamente.',
        'Username/client id combination not found.': 'Usuario no encontrado, intenta iniciar sesión con alguna red social.',
        'Reset Password': 'Restablecer contraseña',
        'Enter your email': 'Ingrese su correo electrónico',
        'Code *': 'Código *',
        'Code': 'Código',
        'Password must have upper case letters': 'La contraseña debe tener letras mayúsculas',
        'Password must have lower case letters': 'La contraseña debe tener letras minúsculas',
        'Password must have numbers': 'La contraseña debe tener números',
        'Attempt limit exceeded, please try after some time.': 'Límite de intentos excedido, por favor intente después de algún tiempo.',
        'Cannot reset password for the user as there is no registered/verified email or phone_number': 'No se puede restablecer la contraseña ya que el correo electrónico no está verificado.',
    },
});