<script setup>
const formFields = {
  signUp: {
    email: {
      order: 2
    },
    name: {
      order: 1
    },
    password: {
      order: 3
    },
    confirm_password: {
      order: 4
    }
  },
  signIn: {
    username: {
      order: 1
    },
    password: {
      order: 2
    }
  }
}
</script>

<template>
  <div class="auth-container">
    <authenticator :form-fields="formFields">

      <template v-slot="{ user, signOut }">
        <h1>Hello {{ user.username }}!</h1>
        <button @click="signOut">Sign Out</button>
      </template>

      <template v-slot:sign-in-header>
        <div class="center-text title-box" style="padding: var(--amplify-space-large) var(--amplify-space-xl) 0 var(--amplify-space-xl);">

          <div class="logo-box">
            <div class="logo">
              <img src="@/assets/logo.png" alt="logo" />
            </div>
          </div>

          <span class="auth-title poppins">
            Bienvenido de vuelta
          </span>

          <span class="auth-subtitle">
            Inicia sesión para continuar administrando
          </span>
        </div>
      </template>

      <template v-slot:reset-password-header>
        <div class="title-box" >

          <div class="logo-box">
            <div class="logo">
              <img src="@/assets/logo.png" alt="logo" />
            </div>
          </div>

          <span class="reset-title poppins">
            Reestablecer contraseña
          </span>

          <span class="reset-subtitle">
            Ingrese su correo electrónico para reestablecer su contraseña
          </span>
        </div>
      </template>

      <template v-slot:confirm-reset-password-header>
        <div class="title-box" >

          <div class="logo-box">
            <div class="logo">
              <img src="@/assets/logo.png" alt="logo" />
            </div>
          </div>

          <span class="reset-title poppins">
            Reestablecer contraseña
          </span>

          <span class="reset-subtitle">
            Ingrese el código que recibió por correo eléctronico para reestablecer su contraseña
          </span>
        </div>
      </template>

      <template v-slot:sign-up-header>
        <div class="center-text title-box" style="padding: var(--amplify-space-large) var(--amplify-space-xl) 0 var(--amplify-space-xl);">

          <div class="logo-box">
            <div class="logo">
              <img src="@/assets/logo.png" alt="logo" />
            </div>
          </div>

          <span class="auth-title poppins">
            Crea tu cuenta
          </span>

          <span class="auth-subtitle">
            Crea tu cuenta para acceder a la plataforma
          </span>
        </div>
      </template>
    </authenticator>
  </div>
</template>

<script>
import { Authenticator } from '@aws-amplify/ui-vue';
import '@aws-amplify/ui-vue/styles.css';

export default {
  name: 'App',
  components: {
    Authenticator
  }
}
</script>

<style>

.auth-container {
  min-height: 100vh;
  min-width: 100vw;
  background: lightgray url("@/assets/background.jpg") no-repeat left top;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-content: center;
}

.logo-box {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.title-box {
  display: flex;
  flex-direction: column;
}

.logo {
  width: 150px;
}

.logo img {
  width: 100%;
}

.auth-title {
  color: #30373D;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 5px;
}

.auth-subtitle {
  color: #8C91A2;
  text-align: center;
  font-size: 14px;
  line-height: 150%;
}

.reset-title {
  color: #30373D;
  text-align: left;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 5px;
}

.reset-subtitle {
  color: #8C91A2;
  text-align: left;
  font-size: 14px;
  line-height: 150%;
}

@media (max-width: 479px) {
  .auth-container {
    background: lightgray url("@/assets/background-mobile.jpg") no-repeat left top;
    background-size: cover;
  }

  .auth-title {
    font-size: 29px;
  }
}

</style>
